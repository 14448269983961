<template>
  <UModal
    v-model="isConnectionModalOpen"
    @close="isConnectionModalOpen = false"
  >
    <UCard>
      <template #header>
        <h1>QRCode</h1>
      </template>
      <div class="flex flex-row w-full justify-center">
        <img :src="image" v-if="image" />
      </div>
      <div v-if="isQrcodeLoading">
        <VLoadingSpinner />
      </div>
    </UCard>
  </UModal>
</template>

<script lang="ts" setup>
import { useQuery } from "@tanstack/vue-query";

const whatsAppStore = useWhatsappChatV1();
const userStore = useUserStore();
const { isConnected, isConnectionModalOpen } = toRefs(whatsAppStore);
const activeRetailChainStore = useActiveRetailChainAndPosStore();

const axiosV2 = useAxios({ useV2: true });
const image = ref("");
const isQrcodeLoading = ref(false);

const getQRCode = async () => {
  const pos = activeRetailChainStore.pos;
  if (!Boolean(pos?.uuid)) {
    isConnected.value = false;
    return;
  }

  try {
    isQrcodeLoading.value = true;
    const response = await axiosV2.get(
      `whatsapp-chat/utils/qr-code?pos=${pos?.uuid}`,
      { responseType: "blob" },
    );

    if (response.data && response.headers["content-type"] === "image/png") {
      isConnectionModalOpen.value = true;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      image.value = url;

      return response.data;
    }

    whatsAppStore.isConnected = true;
    isConnectionModalOpen.value = false;
    return null;
  } catch (e) {
    isConnectionModalOpen.value = false;
  } finally {
    isQrcodeLoading.value = false;
  }
};

useQuery({
  queryKey: ["whatsapp-chat", "utils"],
  queryFn: () => getQRCode(),
  // refetchInterval: 30000,
  enabled: userStore.isMaster || userStore.isManager || false,
});
</script>

<style></style>
