<script setup>
const { userThemes } = useUserStore()
const { theme } = useTheme()
const route = useRoute()
const t = useCustomToast()

onBeforeMount(() => {
  if(!userThemes.includes(route.params.theme)) {
    t.error("Você não tem permissão de acesso a esse recurso do sistema")
    
    navigateTo(`/${theme.value}/login`)
  }
})
</script>

<template>
  <div>
    <AuthenticatedLayout>
      <div
        class="bg-[#F7F7F7] dark:bg-gray-900 h-[92vh] overflow-y-auto"
      >
        <div>
          <slot />
        </div>
      </div>
    </AuthenticatedLayout>
  </div>
</template>
